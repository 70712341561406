var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app-container"},[_c('div',{staticClass:"operate_box"},[_c('el-form',{ref:"formData",attrs:{"model":_vm.formData,"rules":_vm.rules,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"角色","prop":"roleId"}},[_c('el-select',{staticClass:"operate_select",attrs:{"placeholder":"请选择角色"},on:{"change":_vm.changeRole},model:{value:(_vm.formData.roleId),callback:function ($$v) {_vm.$set(_vm.formData, "roleId", $$v)},expression:"formData.roleId"}},_vm._l((_vm.roleData),function(item,index){return _c('el-option',{directives:[{name:"show",rawName:"v-show",value:(
              (!_vm.platformShow && item.spec.name === '平台管理员') ||
              item.spec.name === '店铺管理员' ||
              item.spec.name === '应用管理员' ||
              (!_vm.inviterShow && item.spec.name === '应用用户_邀请员') ||
              item.spec.name === '店铺财务'
            ),expression:"\n              (!platformShow && item.spec.name === '平台管理员') ||\n              item.spec.name === '店铺管理员' ||\n              item.spec.name === '应用管理员' ||\n              (!inviterShow && item.spec.name === '应用用户_邀请员') ||\n              item.spec.name === '店铺财务'\n            "}],key:index,attrs:{"label":item.spec.name,"value":item._id.toHexString()}})}),1)],1),(_vm.isPlatformAdmin && _vm.scopeData.length > 0)?_c('el-form-item',{attrs:{"label":"应用","prop":"scopeId"}},[_c('el-select',{staticClass:"operate_select",attrs:{"placeholder":"请选择应用","filterable":"","multiple":""},model:{value:(_vm.formData.scopeId),callback:function ($$v) {_vm.$set(_vm.formData, "scopeId", $$v)},expression:"formData.scopeId"}},_vm._l((_vm.scopeData),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.spec.name,"value":item._id.toHexString()}})}),1)],1):_vm._e(),(!_vm.isPlatformAdmin && _vm.scopeData.length > 0)?_c('el-form-item',{attrs:{"label":"店铺","prop":"scopeId"}},[_c('el-select',{staticClass:"operate_select",attrs:{"placeholder":"请选择店铺","filterable":"","multiple":""},model:{value:(_vm.formData.scopeId),callback:function ($$v) {_vm.$set(_vm.formData, "scopeId", $$v)},expression:"formData.scopeId"}},_vm._l((_vm.scopeData),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.spec.name,"value":item._id.toHexString()}})}),1)],1):_vm._e()],1)],1),_c('div',{staticClass:"btn_box"},[_c('router-link',{attrs:{"to":'/application/' + _vm.routeApplicationId + '/user/userRoleList/' + _vm.userId}},[_c('el-button',{attrs:{"type":"primary"}},[_vm._v("返回")])],1),_c('el-button',{staticStyle:{"margin-left":"20px"},attrs:{"type":"primary","disabled":_vm.submitFlag},on:{"click":_vm.submitForm}},[_vm._v("保存")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }