
import {Component, Vue} from 'vue-property-decorator';
import {roles, roleMappings, applications, shops} from '../../resources';
import {Form} from 'element-ui';
import {ObjectId} from 'bson';
import {Application} from '@/externals/MaxCI-Application-v1';
import {Role} from '@/externals/Core-Role-v1';
import {Shop} from '@/externals/MaxCI-Shop-v1';
import {RoleMappingSpec} from '@/externals/MaxCI-RoleMapping-v1';

@Component({
  name: 'userRoleAdd',
})
export default class extends Vue {
  private inviterShow = false; //是否显示邀请人选项
  private platformShow = false; //是否显示平台管理员选项
  private submitFlag = false; //提交开关
  private formData = {
    roleId: '', //角色id
    scopeId: [] as Array<string>, // 作用域id
  };
  private rules = {
    roleId: [{required: true, message: '请选择角色', trigger: 'change'}],
    scopeId: [{required: true, message: '请选择店铺', trigger: 'change'}],
  };
  private userId: ObjectId = ObjectId.createFromHexString(
    this.$route.params.userId,
  );
  private roleData: Array<Role> = []; //角色列表
  private scopeData: Array<Application | Shop> = []; //角色列表
  private routeApplicationId = this.$route.params.applicationId;
  private applicationId: ObjectId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private applicationData: Array<Application> = [];
  private shopData: Array<Shop> = []; //店铺管理员已有店铺
  private financeShopData: Array<Shop> = []; //店铺管理员已有店铺

  get isPlatformAdmin() {
    return this.applicationId.equals(this.$store.state.pConfig.applicationId);
  }
  async created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    try {
      //获取角色
      let roleName = [] as Array<string>;
      if (this.isPlatformAdmin) {
        roleName = ['应用管理员', '平台管理员'];
      } else {
        roleName = ['店铺管理员', '应用用户_邀请员', '店铺财务'];
      }
      this.roleData = await roles.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('name'),
            e => e.$in(roleName),
          ),
        ),
      );
      if (this.isPlatformAdmin) {
        //获取用户已有应用
        const applicationIdData = [] as Array<ObjectId>;
        await Promise.all(
          (
            await roleMappings.find(stage =>
              stage.$match(match =>
                match(
                  f => f('spec')('userId'),
                  e => e.$eq(this.userId),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                ),
              ),
            )
          ).map(async v => {
            const role = this.roleData?.find(
              r => r._id.toHexString() === v.spec.roleId.toHexString(),
            );
            if (role !== undefined) {
              if (role.spec.name === '应用管理员') {
                if (v.spec.scopeId) {
                  applicationIdData.push(v.spec.scopeId);
                }
              } else if (role.spec.name === '平台管理员') {
                this.platformShow = true;
              }
            }
          }),
        );
        //查询应用
        this.applicationData = await applications.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$nin(applicationIdData),
            )(
              f => f('status')('phase'),
              e => e.$eq('就绪'),
            )(
              f => f('spec')('name'),
              e => e.$ne('平台应用'),
            ),
          ),
        );
      } else {
        //获取用户已有店铺
        const shopIdData = [] as Array<ObjectId>; //店铺管理员已有店铺
        const financeShopIds = [] as Array<ObjectId>; //店铺财务员已有店铺
        await Promise.all(
          (
            await roleMappings.find(stage =>
              stage.$match(match =>
                match(
                  f => f('spec')('userId'),
                  e => e.$eq(this.userId),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                ),
              ),
            )
          ).map(async v => {
            const role = this.roleData?.find(
              r => r._id.toHexString() === v.spec.roleId.toHexString(),
            );
            if (role !== undefined && v.spec.scopeId) {
              if (role.spec.name === '店铺管理员') {
                shopIdData.push(v.spec.scopeId);
              } else if (role.spec.name === '店铺财务') {
                financeShopIds.push(v.spec.scopeId);
              } else if (role.spec.name === '应用用户_邀请员') {
                this.inviterShow = true;
              }
            }
          }),
        );
        //查询店铺管理员已有店铺
        this.shopData = await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$nin(shopIdData),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          ),
        );
        //查询店铺财务已有店铺
        this.financeShopData = await shops.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$nin(financeShopIds),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          ),
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //获取角色名称
  getRoleName(roleId: ObjectId) {
    let roleName = '';
    this.roleData.forEach(v => {
      if (v._id.equals(roleId)) {
        roleName = v.spec.name;
      }
    });
    return roleName;
  }
  //新增
  private submitForm() {
    (this.$refs.formData as Form).validate(valid => {
      if (valid) {
        try {
          this.submitFlag = true;
          if (this.applicationId) {
            let data = [] as Array<{spec: RoleMappingSpec}>;
            const roleId = ObjectId.createFromHexString(this.formData.roleId);
            if (
              (this.getRoleName(roleId) === '店铺管理员' ||
                this.getRoleName(roleId) === '店铺财务' ||
                this.getRoleName(roleId) === '应用管理员') &&
              this.formData.scopeId.length === 0
            ) {
              this.$message.error(`暂无${this.getRoleName(roleId)}可选`);
              return;
            }
            if (this.formData.scopeId.length === 0) {
              data = [
                {
                  spec: {
                    userId: this.userId,
                    roleId: roleId,
                    applicationId: this.applicationId,
                  },
                },
              ];
            } else {
              data = this.formData.scopeId.map(v => {
                return {
                  spec: {
                    userId: this.userId,
                    roleId: roleId,
                    scopeId: ObjectId.createFromHexString(v),
                    applicationId: this.applicationId,
                  },
                };
              });
            }
            roleMappings.create(data).then(res => {
              if (res.length > 0) {
                this.$message.success('保存成功');
                this.$router.push({
                  path:
                    '/application/' +
                    this.routeApplicationId +
                    '/user/userRoleList/' +
                    this.userId,
                });
              } else {
                this.$message.error('创建失败');
              }
            });
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          console.log(e);
          this.$message.error('网络异常，请稍后重试');
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //选择角色
  private async changeRole(e: string) {
    this.scopeData = [];
    this.formData.scopeId = [];
    for (let i = 0; i < this.roleData.length; i++) {
      if (this.roleData[i]._id.toHexString() === e) {
        if (this.roleData[i].spec.name === '应用管理员') {
          this.scopeData = this.applicationData;
        } else if (this.roleData[i].spec.name === '店铺管理员') {
          this.scopeData = this.shopData;
        } else if (this.roleData[i].spec.name === '店铺财务') {
          this.scopeData = this.financeShopData;
        } else if (this.roleData[i].spec.name === '应用用户_邀请员') {
          this.formData.scopeId = [this.userId.toHexString()];
        }
      }
    }
  }
}
